<template>
  <section>
    <div class="booking-details-card__content">
      <ArtistCard
        :artist="artist"
        isShowActivity
        class="mb-lg">
      </ArtistCard>
      <dl class="booking-details-card__pricing-list">
        <template v-for="(pricingField, index) in pricingFields">
          <Paragraph
            :tag="'dt'"
            :size="'sm'"
            :key="`pricing-field-label-${index}`">
            {{ pricingField.label }}
          </Paragraph>
          <Paragraph
            :tag="'dd'"
            :key="`pricing-field-value-${index}`">
            {{ pricingField.value }} €
          </Paragraph>
        </template>

        <div class="booking-details-card__pricing-total">
          <Paragraph
            :tag="'dt'"
            isBold>
            {{
              isBookingCanceled
                ? `${$t('artist.dashboard.client.booking.details.totalRefund')}`
                : 'Total'
            }}
          </Paragraph>
          <Paragraph
            :tag="'dd'"
            isBold>
            {{ isBookingCanceled ? refundTotal : clientPrice }} €
          </Paragraph>
        </div>
      </dl>
    </div>

    <div
      v-if="isShowBookingInfos || isBooked"
      class="booking-details-card__cancelation-wrapper">
      <Paragraph class="mb-xs">
        {{ $t('artist.dashboard.client.booking.details.cancelHint') }}
      </Paragraph>
      <Loader v-if="isLoading"></Loader>
      <router-link
        v-else
        :to="{
          name: 'ClientBookingCancel',
          params: { id: booking.ID }
        }">
        <TextLink :tag="'span'">
          {{ $t('artist.dashboard.client.booking.details.askCancel') }}
        </TextLink>
      </router-link>
    </div>
  </section>
</template>

<script>

import {
  mapGetters,
}                                 from 'vuex';

import Loader                     from '../../../components/atoms/Loader/a-Loader.vue';
import Paragraph                  from '../../../components/atoms/Paragraph/a-Paragraph.vue';
import TextLink                   from '../../../components/atoms/TextLink/a-TextLink.vue';
import ArtistCard                 from '../ArtistCard/m-ArtistCard.vue';
import CANCELLATION_PLANS         from '../../../constants/cancellationsPlans.js';
import AVAILABILITIES_OPTIONS     from '../../../constants/availabilities-options.js';
import {
  isCurrentOrFutureDate,
}                                 from '../../../utils/dateUtils.js';


export default {
  name: 'm-BookingDetailsCard',
  components: {
    Loader,
    Paragraph,
    TextLink,
    ArtistCard,
  },
  props: {
    booking: {
      type: Object,
      required: true,
    },
    artist: {
      type: Object,
      required: true,
    },
    isBookingCanceled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isLoading: false,
  }),
  computed: {
    ...mapGetters({ isArtist: 'User/IS_ARTIST_USER' }),
    pricingFields() {
      return [
        {
          label: this.$t('common.performance'),
          value: Number(this.artistPrice)?.toFixed(2) ?? 0,
        },
        {
          label: this.$t('common.serviceFees'),
          value: Number(this.clientPrice - this.artistPrice)?.toFixed(2) ?? 0,
        },
        ...(this.isBookingCanceled
          ? [{
              label: this.$t('common.cancelFees'),
              value: this.cancellationFees > 0
                ? `- ${Number( this.cancellationFees ?? 0).toFixed(2)}`
                : 0,
            }]
          : []
        )
      ];
    },
    bookingTarget() {
      return this.isArtist ? this.booking.Client : this.booking.Artist;
    },
    isBooked() {
      return this.booking.Status?.toUpperCase() === AVAILABILITIES_OPTIONS['BOOKED'] &&
        isCurrentOrFutureDate(this.booking.EventDate * 1000);
    },
    isShowBookingInfos() {
      return this.bookingTarget && this.isBooked;
    },
    clientPrice() {
      return Number((this.booking.PriceForClient / 100).toFixed(2));
    },
    artistPrice() {
      return Number((this.booking.PriceForArtist / 100).toFixed(2));
    },
    serviceFees() {
      return Number((this.clientPrice - this.artistPrice).toFixed(2));
    },
    daysBeforeBooking() {
      const millisecondsInADay = 86_400_000;
      const millisecondsBeforeBooking = ((this.booking.EventDate * 1000) - Date.parse(new Date()));

      return Math.ceil(millisecondsBeforeBooking / millisecondsInADay);
    },
    cancellationFees() {
      const details = {
        daysBeforeBooking: this.booking.CanceledBeforeDueDate || this.daysBeforeBooking,
        total: this.clientPrice,
        serviceFees: this.serviceFees,
        isCanceledByClient: this.booking.IsCanceledByClient,
        isArtist: this.isArtist,
      };

      return CANCELLATION_PLANS(details);
    },
    refundTotal() {
      return Number(this.clientPrice - this.cancellationFees).toFixed(2);
    },
  },
}

</script>

<style lang="scss">

.booking-details-card {
  &__content {
    display: flex;
    flex-direction: column;
    padding: var(--space-lg);
    border-radius: var(--rounded-xl);
    background-color: var(--color-white);
    box-shadow: 0 0 10px 5px hsla(0, 0%, 0%, .025);
  }

  &__pricing-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: var(--space-sm);
    align-items: center;

    dd {
      justify-self: end;
    }
  }

  &__pricing-total {
    grid-column: 1 / -1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: var(--space-sm);
    border-top: 1px solid var(--color-grey-empty);
  }

  &__cancelation-wrapper {
    padding: var(--space-lg);
  }
}

</style>

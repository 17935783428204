<template>
  <section class="artist-card__wrapper">
    <router-link
      v-if="artist.ID"
      :to="{ name: 'ArtistProfilePublic', params: { id: artist.ID } }"
      class="artist-card__avatar-wrapper">
      <img
        :src="croppedProfilePictureURL"
        alt="Artist profil picture"
        class="artist-card__avatar"
      />
    </router-link>
    <Icon
      v-else
      :variant="'user-outline'"
      :size="'sm'"
      class="artist-card__avatar"
    />
    <Heading
      :tag="'h3'"
      :level="'h3'"
      isCapitalized
      class="artist-card__firstname">
      {{ artist.Firstname }}
    </Heading>
    <Paragraph
      v-if="artist.ArtisteNicknameGenerated"
      :tag="'span'"
      class="artist-card__nickname">
      @{{ artist.ArtisteNicknameGenerated }}
    </Paragraph>
    <Paragraph
      v-if="isShowActivity"
      :tag="'span'"
      isBold
      class="artist-card__activity">
      {{ $t(getActivityTranslation(artist.Activity)) }}
    </Paragraph>
  </section>
</template>

<script>

import Icon                           from '../../atoms/Icon/a-Icon.vue';
import Heading                        from '../../atoms/Heading/a-Heading.vue';
import Paragraph                      from '../../atoms/Paragraph/a-Paragraph.vue';
import { getCroppedPictureURL }       from '../../../utils/URLUtils.js';
import { getActivityTranslation }     from '../../../utils/i18nUtils.js';


export default {
  name: 'm-ArtistCard',
  components: {
    Icon,
    Heading,
    Paragraph,
  },
  props: {
    artist: {
      type: Object,
      required: true,
    },
    isShowActivity: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    croppedProfilePictureURL() {
      const { URLProfilePictures, ProfilePicturesCropsOptions } = this.artist;

      return this.getCroppedPictureURL(URLProfilePictures, ProfilePicturesCropsOptions, {}, { c: 'fill', w: 100, h: 100, });
    },
  },
  methods: {
    getCroppedPictureURL,
    getActivityTranslation,
  },
}

</script>

<style lang="scss">

.artist-card {
  &__wrapper {
    display: grid;
    grid-template-columns: 50px auto 1fr;
    grid-template-rows: auto auto;
    grid-template-areas:
      "avatar firstname activity"
      "avatar nickname activity"
    ;
    grid-column-gap: var(--space-md);
    align-items: center;
  }

  &__avatar-wrapper {
    grid-area: avatar;
  }

  &__avatar {
    height: 50px;
    width: 50px;
    border: 1px solid var(--color-grey-light);
    border-radius: var(--rounded-xs);
  }

  &__firstname {
    grid-area: firstname;
  }

  &__nickname {
    grid-area: nickname;
  }

  &__activity {
    grid-area: activity;
    margin-left: auto;
  }
}

</style>
